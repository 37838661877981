import React, { useState } from 'react';
import { loginUser, getCurrentUser } from '../api';
import { useNavigate, useLocation } from 'react-router-dom';
import { User, Lock, ArrowRight, Mail } from 'lucide-react';
import { Card, CardHeader, CardContent } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';

const LoginForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // Handle messages passed via navigation state
    React.useEffect(() => {
        if (location.state?.message) {
            setError(location.state.message);
            // Clear the message
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        try {
            // Attempt to login
            const { token } = await loginUser(username, password);
            
            if (!token) {
                throw new Error('No authentication token received');
            }

            // Store the token
            localStorage.setItem('accessToken', token);

            // Verify token by fetching current user
            try {
                await getCurrentUser();
            } catch (verifyError) {
                // If verification fails, clear token and show error
                localStorage.removeItem('accessToken');
                throw new Error('Authentication failed');
            }

            // Navigate to intended destination or dashboard
            const from = location.state?.from?.pathname || '/dashboard';
            navigate(from, { replace: true });

        } catch (error) {
            localStorage.removeItem('accessToken');
            
            // Handle specific error cases
            if (error.response?.status === 401) {
                setError('Invalid username or password.');
            } else if (error.response?.status === 403) {
                setError('Please verify your email before logging in.');
            } else {
                setError(error.message || 'Login failed. Please check your credentials.');
            }
            console.error('Login error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleContact = () => {
        window.location.href = 'mailto:contact@swami.com';
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-white py-12 px-4 sm:px-6 lg:px-8">
            <button
                onClick={handleContact}
                className="fixed top-4 right-4 p-2 flex items-center gap-2 text-gray-600 hover:text-gray-900 bg-white rounded-full shadow-lg hover:shadow-xl transition-all"
            >
                <Mail className="h-5 w-5" />
                <span className="text-sm font-medium">Contact Support</span>
            </button>

            <Card className="max-w-md w-full">
                <CardHeader>
                    <div className="text-center">
                        <h2 className="text-2xl font-bold text-gray-900">
                            Welcome to Swami
                        </h2>
                        <p className="mt-2 text-sm text-gray-500">
                            Sign in to access your picks
                        </p>
                    </div>
                </CardHeader>
                <CardContent>
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div className="space-y-4">
                            {/* Username/Email field */}
                            <div>
                                <label htmlFor="username" className="sr-only">
                                    Username or Email
                                </label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <User className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        id="username"
                                        name="username"
                                        type="text"
                                        required
                                        className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:border-black sm:text-sm"
                                        placeholder="Username or Email"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Password field */}
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Lock className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        required
                                        className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:border-black sm:text-sm"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Error display */}
                        {error && (
                            <Alert className="bg-red-50 border-red-200">
                                <AlertDescription className="text-red-800">
                                    {error}
                                </AlertDescription>
                            </Alert>
                        )}

                        {/* Submit button */}
                        <button
                            type="submit"
                            disabled={loading}
                            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                                loading ? 'bg-gray-400' : 'bg-gray-900 hover:bg-gray-800'
                            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black`}
                        >
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                <ArrowRight className={`h-5 w-5 text-gray-300 group-hover:text-gray-400 ${loading ? 'animate-spin' : ''}`} />
                            </span>
                            {loading ? 'Signing in...' : 'Sign in'}
                        </button>

                        {/* Register link */}
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300"></div>
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">
                                    New to Swami?
                                </span>
                            </div>
                        </div>

                        <button
                            type="button"
                            onClick={() => navigate('/register')}
                            className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-900 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                        >
                            Create an account
                        </button>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

export default LoginForm;