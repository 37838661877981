import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Trophy,
  Users,
  Mail,
  Flame,
  Target,
  Sparkles,
  TrendingUp,
} from 'lucide-react';
import { Button } from './ui/button';
import { Card } from './ui/card';
import { fetchPublicGames } from '../api';
import SwamiLogo from '../SwamiOriginal.png';
import PublicGameCard from './PublicGameCard';

const LandingPage = () => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Handle navbar transparency on scroll
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Fetch games from backend
  useEffect(() => {
    const loadGames = async () => {
      try {
        setLoading(true);
        setError(null);
        const gamesData = await fetchPublicGames();
        
        if (!gamesData || !Array.isArray(gamesData)) {
          console.error('Invalid games data received:', gamesData);
          setError('Unable to load games');
          return;
        }

        // Get next 2 upcoming games
        const futureGames = gamesData
          .filter(game => {
            const gameDate = new Date(game.event_date);
            const now = new Date();
            return gameDate > now;
          })
          .sort((a, b) => new Date(a.event_date) - new Date(b.event_date))
          .slice(0, 2);

        // If no future games, get in-progress games
        if (futureGames.length === 0) {
          const inProgressGames = gamesData
            .filter(game => game.status?.toLowerCase() === 'in_progress' || game.period > 0)
            .sort((a, b) => new Date(a.event_date) - new Date(b.event_date))
            .slice(0, 2);

          setGames(inProgressGames);
        } else {
          setGames(futureGames);
        }
      } catch (error) {
        console.error('Error loading games:', error);
        setError('Unable to load games');
      } finally {
        setLoading(false);
      }
    };

    loadGames();
  }, []);

  const renderGamesSection = () => {
    if (loading) {
      return <div className="p-6 text-center text-gray-500">Loading latest games...</div>;
    }

    if (error) {
      return <div className="p-6 text-center text-gray-500">Unable to load games at the moment.</div>;
    }

    if (!games || games.length === 0) {
      return (
        <div className="p-6 text-center text-gray-500">
          <p>No upcoming games available at the moment.</p>
          <p className="text-sm mt-2">Check back soon for the latest games!</p>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 gap-4 p-4">  {/* Changed from grid-cols-1 md:grid-cols-2 to just grid-cols-1 */}
        {games.map(game => (
          <div key={game.id || game.game_id} className="w-full">
            <PublicGameCard
              game={game}
              onAction={() => navigate('/register')}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Navbar */}
      <nav className={`fixed w-full z-50 transition-all duration-300 ${
        scrolled ? 'bg-white shadow-md' : 'bg-transparent'
      }`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <img src={SwamiLogo} alt="SWAMI" className="h-12 w-auto" />
            <div className="flex items-center gap-4">
              <Button 
                onClick={() => window.location.href = 'mailto:contact@swami.com'}
                variant="ghost"
                className="text-blue-600 hover:text-blue-700 hover:bg-blue-50"
              >
                <Mail className="h-4 w-4 mr-2" />
                Contact
              </Button>
              <Button 
                onClick={() => navigate('/login')}
                variant="ghost"
                className="text-blue-600 hover:text-blue-700 hover:bg-blue-50"
              >
                Sign In
              </Button>
              <Button 
                onClick={() => navigate('/register')}
                className="bg-black text-white hover:bg-gray-800"
              >
                Sign Up
              </Button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="relative min-h-screen flex items-center bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32 relative z-10">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="flex-1 text-center md:text-left">
              <div className="inline-flex items-center bg-black/5 rounded-full px-4 py-2 mb-6">
                <Flame className="h-5 w-5 text-orange-500 mr-2" />
                <span className="text-sm text-black">Join your friends making picks daily</span>
              </div>
              <h1 className="text-5xl md:text-7xl font-bold mb-6 text-black">
                Your Sports.
                <br />
                Your Picks.
                <br />
                Your Glory.
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Make predictions, compete with friends, and prove your sports knowledge.
              </p>
              <Button 
                onClick={() => navigate('/register')}
                className="bg-black hover:bg-gray-800 text-white text-lg px-8 py-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                Start Making Picks
                <Sparkles className="ml-2 h-5 w-5" />
              </Button>
            </div>
            <div className="flex-1">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-100 to-purple-100 rounded-3xl blur-3xl opacity-30 animate-pulse"></div>
                <Card className="relative bg-white shadow-xl rounded-3xl overflow-hidden">
                  {renderGamesSection()}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <Card className="bg-white shadow-xl p-6 rounded-xl hover:transform hover:scale-105 transition-all duration-300">
            <Trophy className="h-12 w-12 text-yellow-500 mb-4" />
            <h3 className="text-xl font-bold mb-2 text-black">Daily Contests</h3>
            <p className="text-gray-600">
              Compete in daily fantasy-style contests across all major sports leagues.
            </p>
          </Card>

          <Card className="bg-white shadow-xl p-6 rounded-xl hover:transform hover:scale-105 transition-all duration-300">
            <Users className="h-12 w-12 text-purple-500 mb-4" />
            <h3 className="text-xl font-bold mb-2 text-black">Private Leagues</h3>
            <p className="text-gray-600">
              Create exclusive leagues and compete with your friends for ultimate bragging rights.
            </p>
          </Card>

          <Card className="bg-white shadow-xl p-6 rounded-xl hover:transform hover:scale-105 transition-all duration-300">
            <TrendingUp className="h-12 w-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-bold mb-2 text-black">Real-Time Stats</h3>
            <p className="text-gray-600">
              Track your picks and stats in real-time with our live dashboard.
            </p>
          </Card>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h2 className="text-4xl md:text-5xl font-bold mb-6 text-black">
              Ready to Prove Your Knowledge?
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Join now and start making your picks
            </p>
            <Button 
              onClick={() => navigate('/register')}
              className="bg-black text-white hover:bg-gray-800 text-lg px-8 py-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
            >
              Create Free Account
              <Target className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="border-t border-gray-200 bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <img src={SwamiLogo} alt="SWAMI" className="h-8 w-auto" />
            <div className="flex gap-6">
              <button onClick={() => navigate('/terms')} className="text-gray-600 hover:text-black">Terms</button>
              <button onClick={() => navigate('/privacy')} className="text-gray-600 hover:text-black">Privacy</button>
              <button onClick={() => window.location.href = 'mailto:contact@swami.com'} className="text-gray-600 hover:text-black">Support</button>
            </div>
          </div>
          <div className="mt-4 text-center text-gray-500 text-sm">
            © {new Date().getFullYear()} Swami. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;