import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import Dashboard from './components/Dashboard';
import GroupDetailView from './components/GroupDetailView';
import CreateGroupForm from './components/CreateGroupForm';
import NewPredictionForm from './components/NewPredictionForm';
import Profile from './components/Profile';
import RegisterForm from './components/RegisterForm';
import PaymentSuccess from './components/PaymentSuccess';
import EmailVerification from './components/EmailVerification';
import LandingPage from './components/LandingPage';
import { getCurrentUser } from './api';

// Modified Protected Route wrapper component
const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isValidating, setIsValidating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('accessToken');
      
      if (!token) {
        setIsValidating(false);
        return;
      }

      try {
        const user = await getCurrentUser();
        if (user) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        localStorage.removeItem('accessToken');
        navigate('/login', { 
          replace: true, 
          state: { message: 'Session expired. Please login again.' } 
        });
      } finally {
        setIsValidating(false);
      }
    };

    validateToken();
  }, [navigate]);

  if (isValidating) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ message: 'Please login to continue.' }} />;
  }

  return children;
};

// Public Route wrapper component
const PublicRoute = ({ children, restricted = false }) => {
  const isAuthenticated = localStorage.getItem('accessToken');

  // If route is restricted (login/register) and user is authenticated,
  // redirect to dashboard
  if (isAuthenticated && restricted) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

function NotFound() {
  const location = useLocation();
  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-xl font-bold mb-4">Route Not Found</h1>
        <div className="bg-gray-50 rounded p-4 mb-4">
          <p className="text-sm text-gray-600">Attempted Path:</p>
          <p className="font-mono text-sm break-all">{location.pathname}</p>
          <p className="text-sm text-gray-600 mt-2">Query Parameters:</p>
          <p className="font-mono text-sm break-all">{location.search}</p>
        </div>
        <button
          onClick={() => window.location.href = '/'}
          className="w-full bg-black text-white rounded py-2 hover:bg-gray-800"
        >
          Return to Home
        </button>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route 
          path="/" 
          element={
            <PublicRoute restricted={false}>
              <LandingPage />
            </PublicRoute>
          } 
        />
        <Route 
          path="/login" 
          element={
            <PublicRoute restricted={true}>
              <LoginForm />
            </PublicRoute>
          } 
        />
        <Route 
          path="/register" 
          element={
            <PublicRoute restricted={true}>
              <RegisterForm />
            </PublicRoute>
          } 
        />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />

        {/* Protected routes */}
        <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/groups/new" 
          element={
            <ProtectedRoute>
              <CreateGroupForm />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/groups/:groupId" 
          element={
            <ProtectedRoute>
              <GroupDetailView />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/predictions/new" 
          element={
            <ProtectedRoute>
              <NewPredictionForm />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/profile" 
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          } 
        />

        {/* Catch-all route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;