import React from 'react';
import { Calendar, Clock } from 'lucide-react';
import { Card, CardContent } from './ui/card';

const TournamentGameCard = ({ 
  game, 
  onPick, 
  selectedTeam, 
  disabled = false 
}) => {
  if (!game) return null;

  // Debug logging
  console.log('TournamentGameCard - Full game data:', game);
  console.log('TournamentGameCard - Game odds:', game.odds);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      timeZone: 'America/Los_Angeles',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', {
      timeZone: 'America/Los_Angeles',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  const formatOdds = (odds) => {
    if (!odds) return null;
    if (Math.abs(odds) < 0.01) return "Even";
    return odds > 0 ? `+${odds}` : odds.toString();
  };

  const validSpread = (spread) => Math.abs(spread) >= 0.5;

  const getBestOdds = (odds, type) => {
    console.log(`getBestOdds called for ${type} with odds:`, odds);
    
    if (!odds || odds.length === 0) return null;

    switch (type) {
      case 'home':
        return odds.reduce((best, current) => {
          const currentOdds = current.moneyline_home || 0;
          const bestOdds = best.moneyline_home || 0;
          return (currentOdds > bestOdds) ? current : best;
        });
      case 'away':
        return odds.reduce((best, current) => {
          const currentOdds = current.moneyline_away || 0;
          const bestOdds = best.moneyline_away || 0;
          return (currentOdds > bestOdds) ? current : best;
        });
      default:
        return null;
    }
  };

  const isGameStarted = () => {
    const now = new Date();
    const gameTime = new Date(game.event_date);
    const pacificGameTime = new Date(gameTime.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles'
    }));
    return now >= pacificGameTime;
  };

  const renderTeamRow = (team, isHome) => {
    const isSelected = selectedTeam === team;
    const firstOdds = game.odds && game.odds[0];
    
    console.log(`renderTeamRow for ${team} (${isHome ? 'home' : 'away'}):`, {
      firstOdds,
      odds: game.odds
    });

    const moneylineOdds = firstOdds ? (isHome ? firstOdds.moneyline_home : firstOdds.moneyline_away) : null;
    const spread = firstOdds ? (isHome ? firstOdds.point_spread_home : firstOdds.point_spread_away) : null;

    console.log(`Team ${team} odds details:`, {
      moneylineOdds,
      spread
    });

    return (
      <button
        onClick={() => onPick(team)}
        disabled={disabled || isGameStarted()}
        className={`w-full p-4 rounded-lg border transition-all ${
          isSelected
            ? 'border-blue-500 bg-blue-50 text-blue-700'
            : 'border-gray-200 hover:bg-gray-50'
        } ${disabled || isGameStarted() ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
      >
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <span className="font-medium text-lg">{team}</span>
              {isSelected && (
                <span className="text-sm font-medium text-blue-600 bg-blue-100 px-2 py-1 rounded">
                  Selected
                </span>
              )}
            </div>
            {moneylineOdds !== null && (
              <span className={`text-sm font-medium ${
                parseFloat(moneylineOdds) > 0 ? 'text-green-600' : 'text-gray-600'
              }`}>
                {formatOdds(moneylineOdds)}
              </span>
            )}
          </div>
          
          {spread !== null && validSpread(spread) && (
            <div className="text-sm text-gray-600">
              Spread: {spread > 0 ? '+' : ''}{spread}
            </div>
          )}
        </div>
      </button>
    );
  };

  return (
    <Card className="bg-white">
      <CardContent className="p-4">
        {/* Date and Time Header */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <Calendar className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">{formatDate(game.event_date)}</span>
          </div>
          <div className="flex items-center space-x-2">
            <Clock className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">{formatTime(game.event_date)} PT</span>
          </div>
        </div>

        {/* Teams Section */}
        <div className="space-y-3">
          {renderTeamRow(game.home_team, true)}
          {renderTeamRow(game.away_team, false)}
        </div>

        {/* Game Status */}
        <div className="mt-3 text-center">
          {isGameStarted() && (
            <p className="text-sm text-gray-500 mt-2">
              Picks are locked because the game has started.
            </p>
          )}
          {!isGameStarted() && (!game.odds || game.odds.length === 0) && (
            <p className="text-sm text-gray-500 mt-2">
              Odds are not yet available, but you can still make your pick!
            </p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default TournamentGameCard;