import React from 'react';
import { Calendar, Clock } from 'lucide-react';
import { Card } from './ui/card';

const PublicGameCard = ({ game, onAction }) => {
    if (!game) return null;
  
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    };
  
    const formatTime = (dateString) => {
      if (!dateString) return '';
      try {
        const date = new Date(dateString);
        return date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit'
        });
      } catch (error) {
        return '';
      }
    };
  
    const getGameStatus = () => {
      const status = game.status?.toLowerCase() || '';
      const gameDate = new Date(game.event_date);
      const now = new Date();
  
      if (status === 'in_progress' || status === 'in-progress' || game.period > 0) {
        return {
          style: 'bg-green-100 text-green-800',
          text: 'IN PROGRESS'
        };
      }
  
      if (gameDate > now || status === 'scheduled' || status === 'upcoming') {
        return {
          style: 'bg-blue-100 text-blue-800',
          text: 'UPCOMING'
        };
      }
  
      return {
        style: 'bg-gray-100 text-gray-800',
        text: 'SCHEDULED'
      };
    };
  
    const formatOdds = (odds) => {
      if (!odds) return '';
      if (Math.abs(odds) < 0.01) return "Pick 'Em";
      return odds > 0 ? `+${odds}` : odds.toString();
    };
  
    const getBestOdds = (type) => {
      if (!game.odds || game.odds.length === 0) return null;
      const firstOdds = game.odds[0];
      return type === 'home' ? firstOdds.moneyline_home : firstOdds.moneyline_away;
    };
  
    const validSpread = (spread) => spread && Math.abs(spread) >= 0.5;
  
    const gameStatus = getGameStatus();
    const homeOdds = getBestOdds('home');
    const awayOdds = getBestOdds('away');
  
    return (
      <Card className="bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
        <div className="p-4">
          <div className="flex justify-between items-center mb-3">
            <div className="flex items-center space-x-2">
              <Calendar className="h-4 w-4 text-gray-500" />
              <span className="text-sm text-gray-600">{formatDate(game.event_date)}</span>
            </div>
            <div className="flex items-center space-x-2">
              <Clock className="h-4 w-4 text-gray-500" />
              <span className="text-sm text-gray-600">{formatTime(game.event_date)}</span>
            </div>
          </div>
  
          <div className="flex justify-between items-center mb-4">
            <div className="space-y-3 flex-1">
              <div className="flex justify-between items-center">
                <span className="font-semibold">{game.home_team}</span>
                <span className="text-sm font-medium text-gray-600 ml-2">
                  {formatOdds(homeOdds)}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="font-semibold">{game.away_team}</span>
                <span className="text-sm font-medium text-gray-600 ml-2">
                  {formatOdds(awayOdds)}
                </span>
              </div>
            </div>
          </div>
  
          {/* Display Spread if available */}
          {game.odds && game.odds.length > 0 && validSpread(game.odds[0].point_spread_home) && (
            <div className="text-center border-t pt-3 mb-3">
              <div className="text-sm text-gray-500">
                Spread: Home {game.odds[0].point_spread_home || 'N/A'} | Away {game.odds[0].point_spread_away || 'N/A'}
              </div>
            </div>
          )}
  
          <div className="mt-3 flex justify-between items-center">
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${gameStatus.style}`}>
              {gameStatus.text}
            </span>
            <button
              onClick={() => onAction?.(game)}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
            >
              Sign Up to Pick
            </button>
          </div>
        </div>
      </Card>
    );
  };
  
  export default PublicGameCard;